<template>
  <div class="g-section">
    <div class="g-wrap wrap-main">
      <div class="wrap-left">
        <el-menu default-active="1" class="el-menu-vertical-demo w100">
          <el-menu-item index="1">
            <i class="el-icon-menu"></i>
            <span slot="title">基本信息</span>
          </el-menu-item>
          <el-menu-item index="2">
            <i class="el-icon-document"></i>
            <span slot="title">求职意向</span>
          </el-menu-item>
          <el-menu-item index="3">
            <i class="el-icon-setting"></i>
            <span slot="title">工作经验</span>
          </el-menu-item>
          <el-menu-item index="4">
            <i class="el-icon-setting"></i>
            <span slot="title">项目经验</span>
          </el-menu-item>
          <el-menu-item index="5">
            <i class="el-icon-setting"></i>
            <span slot="title">教育经历</span>
          </el-menu-item>
          <el-menu-item index="6">
            <i class="el-icon-setting"></i>
            <span slot="title">获奖情况</span>
          </el-menu-item>
          <el-menu-item index="7">
            <i class="el-icon-setting"></i>
            <span slot="title">技能特长</span>
          </el-menu-item>
          <el-menu-item index="8">
            <i class="el-icon-setting"></i>
            <span slot="title">个人作品</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="wrap-right">
        <div class="wrap-module">
          <div class="resume-module-top">
            <div class="resume-title-box"><i class="el-icon-menu"></i>
              <span class="resume-title">基本信息</span>
            </div>
            <el-button type="text" icon="el-icon-edit" @click="editBaseInfo">编辑</el-button>
          </div>
          <div class="resume-module-bottom">
             姓名，相片，手机号，邮箱，身份证号等，求职状态等
          </div>
        </div>
        <div class="wrap-module">
          <div class="resume-module-top">
            <div class="resume-title-box"><i class="el-icon-menu"></i>
              <span class="resume-document">求职意向</span>
            </div>
            <el-button type="text" icon="el-icon-edit" @click="editBaseInfo">编辑</el-button>
          </div>
          <div class="resume-module-bottom">
             行业，岗位，期望薪资等
          </div>
        </div>
        <div class="wrap-module">
          <div class="resume-module-top">
            <div class="resume-title-box"><i class="el-icon-menu"></i>
              <span class="resume-title">工作经验</span>
            </div>
            <el-button type="text" icon="el-icon-plus" @click="addExperience">新增工作</el-button>
          </div>
          <div class="resume-module-bottom">
            <experience v-for="(item,i) in listExperience" :item-data="item" :key="i"></experience>
          </div>
        </div>
        <div class="wrap-module">
          <div class="resume-module-top">
            <div class="resume-title-box"><i class="el-icon-menu"></i>
              <span class="resume-title">教育经历</span>
            </div>
            <el-button type="text" icon="el-icon-plus" @click="addExperience">新增教育</el-button>
          </div>
          <div class="resume-module-bottom">
            <experience v-for="(item,i) in listExperience" :item-data="item" :key="i"></experience>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Experience from './components/Experience'
export default {
  components: { Experience },
  data() {
    return {
      listExperience: [{ Id: '1', Name: '深圳大智慧', IsView: true }, { Id: '2', Name: '深圳智邻', IsView: true }]
    }
  }, methods: {
    addExperience() {
      const newExperience = {
        Id: '',
        Name: '',
        IsView: false
      }
      this.listExperience.push(newExperience)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-left {
  display: flex;
  width: 210px;
}
.wrap-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
  padding: 10px;
}
.wrap-module {
  display: flex;
  flex-direction: column;
  min-height: 120px;
  border-bottom: 1px solid #dddddd;
}
.resume-module-top {
  display: flex;
  justify-content: space-between;
  .resume-title-box {
    display: flex;
    color: #409eff;
    font-size: 20px;
    align-items: center;
  }
  .resume-title {
    margin-left: 5px;
    color: #409eff;
    font-size: 18px;
  }
}
.resume-module-bottom {
  display: flex;
  flex-direction: column;
}
</style>
<template>
  <div v-if="isView" class="item-box">
    <div class="resume-item">
      <div class="item-texts">
        <span class="">2016.8-2018.6</span>
        <span>{{itemData.Name}}</span>
        <span>前端开发工程师</span>
      </div>
    </div>
    <div class="resume-item">
      <span>计算机软件 | 民营公司 | 研发中心</span>
    </div>
    <div class="resume-item">
      <span class="item-text">工作描述：主要从事软件项目的设计与实现，负责项目需求分析、数据库设计、系统设计与实现、系统测试等主要从事软件项目的设计与实现，负责项目需求分析、数据库设计、系统设计与实现、系统测试等</span>
    </div>
    <div class="resume-item-op">
      <div class="item-buttons">
        <el-button type="text" size="small" icon="el-icon-edit" @click="handleEdit">编辑</el-button>
        <el-button type="text" size="small" icon="el-icon-delete">删除</el-button>
      </div>
    </div>
  </div>
  <div v-else class="item-box">
    <div>
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="时间" prop="field102">
              <el-date-picker type="daterange" v-model="formData.field102" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :style="{width: '100%'}"
                start-placeholder="开始日期" end-placeholder="结束日期" range-separator="至" clearable></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="公司" prop="field101">
              <el-input v-model="formData.field101" placeholder="请输入公司" clearable :style="{width: '100%'}">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职位" prop="field103">
              <el-input v-model="formData.field103" placeholder="请输入单行文本" clearable :style="{width: '100%'}">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="工作描述" prop="field104">
              <el-input v-model="formData.field104" type="textarea" placeholder="请输入多行文本" :autosize="{minRows: 4, maxRows: 4}"
                :style="{width: '100%'}"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="resume-item-op">
      <div class="btn-ops">
        <el-button type="primary" size="small" icon="el-icon-edit" @click="handleSave">保存</el-button>
        <el-button type="primary" plain size="small" icon="el-icon-delete" @click="handleCancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Experience',
  data() {
    return {
      isView: true,
      formData: {},
      rules: {
        field102: [{
          required: true,
          message: '时间不能为空',
          trigger: 'change'
        }],
        field101: [{
          required: true,
          message: '请输入公司',
          trigger: 'blur'
        }],
        field103: [{
          required: true,
          message: '请输入单行文本',
          trigger: 'blur'
        }],
        field104: [{
          required: true,
          message: '请输入多行文本',
          trigger: 'blur'
        }],
      }
    }
  },
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {
          Id: '',
          Name: '智慧啊',
          IsView:true
        }
      }
    }
  },
  mounted(){
    this.isView=this.itemData.IsView
  },
  methods: {
    handleEdit() {
      this.isView = false
      this.formData = Object.assign({}, this.itemData)
    },
    handleSave() {
      this.isView = true
    },
    handleCancel(){
       this.isView = true
    }

  }

}
</script>

<style lang="scss" scoped>
.item-box {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  .item-texts {
    width: 700px;
    display: flex;
    justify-content: space-between;
  }
  .item-buttons {
    display: none;
  }
  &:hover {
    .item-buttons {
      display: inline-block;
    }
  }
  .btn-ops {
  }
}
.item-box + .item-box {
  border-top: 1px dashed #dddddd;
  padding-top: 10px;
}
.resume-item {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.resume-item-op {
  display: flex;
  justify-content: space-between;
  min-height: 36px;
  flex: 1;
  align-items: center;
}
.item-text {
  display: inline-block;
  text-align: left;
}
</style>